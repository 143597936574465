import {
  AppstoreAddOutlined,
  ApartmentOutlined,
  FileDoneOutlined,
  NotificationOutlined,
  SelectOutlined,
  ExportOutlined,
  BankOutlined,
  FilePdfOutlined,
  MobileOutlined,
  CreditCardOutlined,
  BarChartOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH, ADMIN_PREFIX_PATH } from "configs/AppConfig";

const systemNavTree = [
  {
    key: "system-nav",
    path: `${APP_PREFIX_PATH}/system`,
    title: "sidenav.system",
    icon: ApartmentOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "system-setupuser",
        path: `${APP_PREFIX_PATH}/system/setup-user`,
        title: "sidenav.system.setupuser",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "system-closingperiode",
        path: `${APP_PREFIX_PATH}/system/closing-periode`,
        title: "sidenav.system.closingperiode",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "system-restoreperiode",
        path: `${APP_PREFIX_PATH}/system/restore-periode`,
        title: "sidenav.system.restoreperiode",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "system-hpprecalculation",
        path: `${APP_PREFIX_PATH}/system/hpp-recalculation`,
        title: "sidenav.system.hpprecalculation",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sidenav.system.uploadlogo",
        path: `${APP_PREFIX_PATH}/system/upload-logo`,
        title: "sidenav.system.uploadlogo",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const masterNavTree = [
  {
    key: "master-nav",
    path: `${APP_PREFIX_PATH}/master`,
    title: "sidenav.master",
    icon: AppstoreAddOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "coa",
        path: `${APP_PREFIX_PATH}/master/chart-of-account`,
        title: "sidenav.master.coa",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "branch",
        path: `${APP_PREFIX_PATH}/master/branch`,
        title: "sidenav.master.branch",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "greetingmessage",
        path: `${APP_PREFIX_PATH}/master/greeting-message`,
        title: "sidenav.master.greetingmessage",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "stock",
        path: `${APP_PREFIX_PATH}/master/stock`,
        title: "sidenav.master.stock",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "stockcategory",
        path: `${APP_PREFIX_PATH}/master/stock-category`,
        title: "sidenav.master.stockcategory",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "stockunit",
        path: `${APP_PREFIX_PATH}/master/stock-unit`,
        title: "sidenav.master.stockunit",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "supplier",
        path: `${APP_PREFIX_PATH}/master/supplier`,
        title: "sidenav.master.supplier",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "customer",
        path: `${APP_PREFIX_PATH}/master/customer`,
        title: "sidenav.master.customer",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "warehouse",
        path: `${APP_PREFIX_PATH}/master/warehouse`,
        title: "sidenav.master.warehouse",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "master-edc",
        path: `${APP_PREFIX_PATH}/master/master-edc`,
        title: "sidenav.master.masteredc",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "change-selling-price",
        path: `${APP_PREFIX_PATH}/master/changes-selling-price`,
        title: "sidenav.master.changesellingprice",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "masterpoint",
        path: `${APP_PREFIX_PATH}/master/master-point`,
        title: "sidenav.master.masterpoint",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "mastermargin",
        path: `${APP_PREFIX_PATH}/master/master-margin`,
        title: "sidenav.master.mastermargin",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const applicationNavTree = [
  {
    key: "application-nav",
    path: `${APP_PREFIX_PATH}/application`,
    title: "sidenav.application",
    icon: MobileOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "application-banner",
        path: `${APP_PREFIX_PATH}/application/banner`,
        title: "sidenav.application.banner",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const stockNavTree = [
  {
    key: "stock-nav",
    path: `${APP_PREFIX_PATH}/stock`,
    title: "sidenav.stock",
    icon: FileDoneOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "stock-stockmutation",
        path: `${APP_PREFIX_PATH}/stock/mutation`,
        title: "sidenav.stock.stockmutation",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "stock-stockcorrection",
        path: `${APP_PREFIX_PATH}/stock/correction`,
        title: "sidenav.stock.stockcorrection",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "stock-stockopname",
        path: `${APP_PREFIX_PATH}/stock/opname`,
        title: "sidenav.stock.stockopname",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const purchaseNavTree = [
  {
    key: "purchase-nav",
    path: `${APP_PREFIX_PATH}/purchase`,
    title: "sidenav.purchase",
    icon: SelectOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "purchase-purchaseorderrecommendation",
        path: `${APP_PREFIX_PATH}/purchase/purchase-order-recommendation`,
        title: "sidenav.purchase.purchaseorderrecommendation",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-purchaseorder",
        path: `${APP_PREFIX_PATH}/purchase/purchase-order`,
        title: "sidenav.purchase.purchaseorder",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-goodsreceipt",
        path: `${APP_PREFIX_PATH}/purchase/receive-order`,
        title: "sidenav.purchase.goodsreceipt",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-invoice",
        path: `${APP_PREFIX_PATH}/purchase/invoice`,
        title: "sidenav.purchase.invoice",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-return",
        path: `${APP_PREFIX_PATH}/purchase/return`,
        title: "sidenav.purchase.return",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-pastreturn",
        path: `${APP_PREFIX_PATH}/purchase/past-return`,
        title: "sidenav.purchase.pastreturn",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-debitnote",
        path: `${APP_PREFIX_PATH}/purchase/debit-note`,
        title: "sidenav.purchase.debitnote",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-creditnote",
        path: `${APP_PREFIX_PATH}/purchase/credit-note`,
        title: "sidenav.purchase.creditnote",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-others",
        path: `${APP_PREFIX_PATH}/purchase/other-debt`,
        title: "sidenav.purchase.others",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-debtdownpayment",
        path: `${APP_PREFIX_PATH}/purchase/debt-down-payment`,
        title: "sidenav.purchase.debtdownpayment",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-supplierreceipt",
        path: `${APP_PREFIX_PATH}/purchase/supplier-receipt`,
        title: "sidenav.purchase.supplierreceipt",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-debtrepayment",
        path: `${APP_PREFIX_PATH}/purchase/debt-repayment`,
        title: "sidenav.purchase.debtrepayment",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "purchase-purchasepricehistory",
        path: `${APP_PREFIX_PATH}/purchase/purchase-price-history`,
        title: "sidenav.purchase.purchasepricehistory",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const salesNavTree = [
  {
    key: "sales-nav",
    path: `${APP_PREFIX_PATH}/sales`,
    title: "sidenav.sales",
    icon: ExportOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "sales-booking",
        path: `${APP_PREFIX_PATH}/sales/booking`,
        title: "sidenav.sales.booking",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-order",
        path: `${APP_PREFIX_PATH}/sales/order`,
        title: "sidenav.sales.order",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-cashier",
        path: `${APP_PREFIX_PATH}/sales/cashier`,
        title: "sidenav.sales.cashier",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-dowarehouse",
        path: `${APP_PREFIX_PATH}/sales/do-warehouse`,
        title: "sidenav.sales.dowarehouse",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-opencod",
        path: `${APP_PREFIX_PATH}/sales/open-cod`,
        title: "sidenav.sales.opencod",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-outerwarehousemutation",
        path: `${APP_PREFIX_PATH}/sales/outer-warehouse-mutation`,
        title: "sidenav.sales.outerwarehousemutation",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-outerwarehousemutationreturns",
        path: `${APP_PREFIX_PATH}/sales/outer-warehouse-mutation-returns`,
        title: "sidenav.sales.outerwarehousemutationreturns",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-salesinvoice",
        path: `${APP_PREFIX_PATH}/sales/invoice`,
        title: "sidenav.sales.salesinvoice",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-salesreturns",
        path: `${APP_PREFIX_PATH}/sales/sales-returns`,
        title: "sidenav.sales.salesreturns",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-debitnote",
        path: `${APP_PREFIX_PATH}/sales/debit-note`,
        title: "sidenav.sales.debitnote",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-creditnote",
        path: `${APP_PREFIX_PATH}/sales/credit-note`,
        title: "sidenav.sales.creditnote",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-pastsalesreturns",
        path: `${APP_PREFIX_PATH}/sales/past-sales-returns`,
        title: "sidenav.sales.pastsalesreturns",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-deliverynotes",
        path: `${APP_PREFIX_PATH}/sales/delivery-notes`,
        title: "sidenav.sales.deliverynotes",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-salesdebitnotes",
        path: `${APP_PREFIX_PATH}/sales/sales-debit-notes`,
        title: "sidenav.sales.salesdebitnotes",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-salescreditnotes",
        path: `${APP_PREFIX_PATH}/sales/sales-credit-notes`,
        title: "sidenav.sales.salescreditnotes",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-others",
        path: `${APP_PREFIX_PATH}/sales/other-receivables`,
        title: "sidenav.sales.othercustomeraccount",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-receivabledownpayment",
        path: `${APP_PREFIX_PATH}/sales/receivable-down-payment`,
        title: "sidenav.sales.receivabledownpayment",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-repaymentofreceivables",
        path: `${APP_PREFIX_PATH}/sales/repayment-of-receivable`,
        title: "sidenav.sales.repaymentofreceivables",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-redeempoint",
        path: `${APP_PREFIX_PATH}/sales/redeempoint`,
        title: "sidenav.sales.redeempoint",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-pointcorrection",
        path: `${APP_PREFIX_PATH}/sales/point-correction`,
        title: "sidenav.sales.pointcorrection",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "sales-historyofsellingtrasactionsperitem",
        path: `${APP_PREFIX_PATH}/sales/history-of-selling-trasactions-peritem`,
        title: "sidenav.sales.historyofsellingtrasactionsperitem",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const campaignNavTree = [
  {
    key: "campaign-nav",
    path: `${APP_PREFIX_PATH}/campaign`,
    title: "sidenav.campaign",
    icon: NotificationOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "campaign.gift",
        path: `${APP_PREFIX_PATH}/campaign/gift`,
        title: "sidenav.campaign.gift",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "campaign.discount",
        path: `${APP_PREFIX_PATH}/campaign/discount`,
        title: "sidenav.campaign.discount",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "campaign.promotion",
        path: `${APP_PREFIX_PATH}/campaign/promotion`,
        title: "sidenav.campaign.promotion",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const bankcashNavTree = [
  {
    key: "bankcash-nav",
    path: `${APP_PREFIX_PATH}/bank-cash`,
    title: "sidenav.bankcash",
    icon: BankOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "bankcash-cashreceipts",
        path: `${APP_PREFIX_PATH}/bank-cash/cash-receipts`,
        title: "sidenav.bankcash.cashreceipts",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "bankcash-cashexpenses",
        path: `${APP_PREFIX_PATH}/bank-cash/cash-expenses`,
        title: "sidenav.bankcash.cashexpenses",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "bankcash-bankreceipts",
        path: `${APP_PREFIX_PATH}/bank-cash/bank-receipts`,
        title: "sidenav.bankcash.bankreceipts",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "bankcash-bankexpenses",
        path: `${APP_PREFIX_PATH}/bank-cash/bank-expenses`,
        title: "sidenav.bankcash.bankexpenses",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "bankcash-generaljournal",
        path: `${APP_PREFIX_PATH}/bank-cash/general-journal`,
        title: "sidenav.bankcash.generaljournal",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "bankcash-journalofadjustments",
        path: `${APP_PREFIX_PATH}/bank-cash/adjustment-journal`,
        title: "sidenav.bankcash.journalofadjustments",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "bankcash-disbursement-cc",
        path: `${APP_PREFIX_PATH}/bank-cash/disbursement-cc`,
        title: "sidenav.bankcash.disbursement-cc",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "bankcash-disbursement-dc",
        path: `${APP_PREFIX_PATH}/bank-cash/disbursement-dc`,
        title: "sidenav.bankcash.disbursement-dc",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const reportNavTree = [
  {
    key: "report-nav",
    path: `${APP_PREFIX_PATH}/report`,
    title: "sidenav.report",
    icon: FilePdfOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "report-stockreport",
        path: `${APP_PREFIX_PATH}/report/stock-report`,
        title: "sidenav.report.stockreport",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-purchasereport",
        path: `${APP_PREFIX_PATH}/report/purchase-report`,
        title: "sidenav.report.purchasereport",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-salesreport",
        path: `${APP_PREFIX_PATH}/report/sales-report`,
        title: "sidenav.report.salesreport",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-bomreport",
        path: `${APP_PREFIX_PATH}/report/bom-report`,
        title: "sidenav.report.bomreport",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-promotionreport",
        path: `${APP_PREFIX_PATH}/report/promotion-report`,
        title: "sidenav.report.promotionreport",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-financialstatements",
        path: `${APP_PREFIX_PATH}/report/financial-statements`,
        title: "sidenav.report.financialstatements",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-purchaseanalysis",
        path: `${APP_PREFIX_PATH}/report/purchase-analysis`,
        title: "sidenav.report.purchaseanalysis",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-salesanalysis",
        path: `${APP_PREFIX_PATH}/report/sales-analysis`,
        title: "sidenav.report.salesanalysis",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-salesdashboard",
        path: `${APP_PREFIX_PATH}/report/sales-dashboard`,
        title: "sidenav.report.salesdashboard",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-salesreportpercashier",
        path: `${APP_PREFIX_PATH}/report/sales-report-percashier`,
        title: "sidenav.report.salesreportpercashier",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-stockageanalysis",
        path: `${APP_PREFIX_PATH}/report/stock-age-analysis`,
        title: "sidenav.report.stockageanalysis",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "report-dailyreport",
        path: `${APP_PREFIX_PATH}/report/daily-report`,
        title: "sidenav.report.dailyreport",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const payLaterNavTree = [
  {
    key: "paylater-nav",
    path: `${APP_PREFIX_PATH}/paylater`,
    title: "sidenav.paylater",
    icon: CreditCardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "paylater-payment",
        path: `${APP_PREFIX_PATH}/paylater/payment`,
        title: "sidenav.paylater.payment",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const analyticNavTree = [
  {
    key: "analytic-nav",
    path: `${APP_PREFIX_PATH}/analytic`,
    title: "sidenav.analytic",
    icon: BarChartOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "analytic-summary-sales",
        path: `${APP_PREFIX_PATH}/analytic/summary-sales`,
        title: "sidenav.analytic.summary-sales",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "analytic-sales-insights",
        path: `${APP_PREFIX_PATH}/analytic/sales-insights`,
        title: "sidenav.analytic.sales-insights",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "analytic-customer-insights",
        path: `${APP_PREFIX_PATH}/analytic/customer-insights`,
        title: "sidenav.analytic.customer-insights",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "analytic-financial-insights",
        path: `${APP_PREFIX_PATH}/analytic/financial-insights`,
        title: "sidenav.analytic.financial-insights",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "analytic-stock-insights",
        path: `${APP_PREFIX_PATH}/analytic/stock-insights`,
        title: "sidenav.analytic.stock-insights",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "analytic-purchase-insights",
        path: `${APP_PREFIX_PATH}/analytic/purchase-insights`,
        title: "sidenav.analytic.purchase-insights",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const bomNavTree = [
  {
    key: "bom-nav",
    path: `${APP_PREFIX_PATH}/bom`,
    title: "sidenav.bom",
    icon: FileTextOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "bom-jobcosting",
        path: `${APP_PREFIX_PATH}/bom/job-costing`,
        title: "sidenav.bom.jobcosting",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

//AdminLayout
const systemNavTreeAdmin = [
  {
    key: "system-nav-admin",
    path: `${ADMIN_PREFIX_PATH}/system`,
    title: "sidenav.system.admin",
    icon: ApartmentOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "system-setupaccess-admin",
        path: `${ADMIN_PREFIX_PATH}/system/setup-access`,
        title: "sidenav.system.setupaccess.admin",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "system-setupuser-admin",
        path: `${ADMIN_PREFIX_PATH}/system/setup-user`,
        title: "sidenav.system.setupuser.admin",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "system-operational-admin",
        path: `${ADMIN_PREFIX_PATH}/system/operational`,
        title: "sidenav.system.operational.admin",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "background-process-admin",
        path: `${ADMIN_PREFIX_PATH}/system/background-process`,
        title: "sidenav.system.backgroundprocess.admin",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const masterNavTreeAdmin = [
  {
    key: "master-nav-admin",
    path: `${ADMIN_PREFIX_PATH}/master`,
    title: "sidenav.master.admin",
    icon: AppstoreAddOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "company-admin",
        path: `${ADMIN_PREFIX_PATH}/master/company`,
        title: "sidenav.master.company.admin",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "branch-admin",
        path: `${ADMIN_PREFIX_PATH}/master/branch`,
        title: "sidenav.master.branch.admin",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "target-admin",
        path: `${ADMIN_PREFIX_PATH}/master/target`,
        title: "sidenav.master.target.admin",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "stock-admin",
        path: `${ADMIN_PREFIX_PATH}/master/stock`,
        title: "sidenav.master.stock.admin",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "stockcategory-admin",
        path: `${ADMIN_PREFIX_PATH}/master/stock-category`,
        title: "sidenav.master.stockcategory.admin",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "stockunit-admin",
        path: `${ADMIN_PREFIX_PATH}/master/stock-unit`,
        title: "sidenav.master.stockunit.admin",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [
  ...systemNavTree,
  ...masterNavTree,
  ...applicationNavTree,
  ...stockNavTree,
  ...purchaseNavTree,
  ...salesNavTree,
  ...payLaterNavTree,
  ...campaignNavTree,
  ...bankcashNavTree,
  ...analyticNavTree,
  ...bomNavTree,
  ...reportNavTree,
  ...systemNavTreeAdmin,
  ...masterNavTreeAdmin,
];

export default navigationConfig;
