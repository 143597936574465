/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { signOut } from "views/auth-views/login/redux";
import { useHistory } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { LogoutOutlined, AppstoreOutlined, ShopOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Avatar, Divider, Badge } from "antd";
import { ADMIN_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const NavProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.user.currentUser,
  }));

  const onSignOut = () => {
    dispatch(signOut());
  };

  return (
    <Dropdown
      trigger={["click", "hover"]}
      placement="bottomRight"
      overlay={
        <Menu>
          <Menu.Item disabled key={1} className="text-center">
            <h5 className="mb-0">{currentUser && currentUser.employee.name}</h5>
            <div className="m-1 d-flex justify-content-center align-items-center">
              <div>
                <Badge
                  count={currentUser && currentUser.role.name}
                  style={{
                    color: "#fff",
                    fontSize: "10px",
                    backgroundColor: "#f50",
                  }}
                />
              </div>
              {currentUser && currentUser.roleAdmin && <Divider type="vertical" />}
              {currentUser && currentUser.roleAdmin && (
                <div>
                  <Badge
                    count={currentUser && currentUser.roleAdmin.name}
                    style={{
                      color: "#fff",
                      fontSize: "10px",
                      backgroundColor: "hsl(102, 53%, 61%)",
                    }}
                  />
                </div>
              )}
            </div>
          </Menu.Item>
          <Divider className="m-0" />
          {!window.location.pathname.includes(ADMIN_PREFIX_PATH) ? (
            <Menu.Item
              className="d-block d-md-none"
              key={2}
              icon={<AppstoreOutlined style={{ fontSize: "18px" }} />}
              onClick={() => history.push(ADMIN_PREFIX_PATH)}
            >
              Admin
            </Menu.Item>
          ) : (
            <Menu.Item
              className="d-block d-md-none"
              key={2}
              icon={<ShopOutlined style={{ fontSize: "18px" }} />}
              onClick={() => history.push(APP_PREFIX_PATH)}
            >
              Cabang
            </Menu.Item>
          )}
          <Menu.Item
            key={4}
            icon={<LogoutOutlined style={{ fontSize: "18px" }} />}
            onClick={onSignOut}
          >
            Sign Out
          </Menu.Item>
        </Menu>
      }
    >
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar icon={<UserOutlined />} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavProfile;
