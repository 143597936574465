/* eslint-disable array-callback-return */
import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { useSelector } from "react-redux";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import _ from "lodash";

export const AppViews = () => {
  const { accessMenus } = useSelector((state) => state.user);

  const listMenu = [
    {
      menu: "sidenav.system",
      path: `${APP_PREFIX_PATH}/system`,
      component: lazy(() => import(`./system`)),
    },
    {
      menu: "sidenav.master",
      path: `${APP_PREFIX_PATH}/master`,
      component: lazy(() => import(`./master`)),
    },
    {
      menu: "sidenav.stock",
      path: `${APP_PREFIX_PATH}/stock`,
      component: lazy(() => import(`./stock`)),
    },
    {
      menu: "sidenav.purchase",
      path: `${APP_PREFIX_PATH}/purchase`,
      component: lazy(() => import(`./purchase`)),
    },
    {
      menu: "sidenav.sales",
      path: `${APP_PREFIX_PATH}/sales`,
      component: lazy(() => import(`./sales`)),
    },
    {
      menu: "sidenav.paylater",
      path: `${APP_PREFIX_PATH}/paylater`,
      component: lazy(() => import(`./paylater`)),
    },
    {
      menu: "sidenav.campaign",
      path: `${APP_PREFIX_PATH}/campaign`,
      component: lazy(() => import(`./campaign`)),
    },
    {
      menu: "sidenav.bankcash",
      path: `${APP_PREFIX_PATH}/bank-cash`,
      component: lazy(() => import(`./bank-cash`)),
    },
    {
      menu: "sidenav.bom",
      path: `${APP_PREFIX_PATH}/bom`,
      component: lazy(() => import(`./bill-of-material`)),
    },
    {
      menu: "sidenav.report",
      path: `${APP_PREFIX_PATH}/report`,
      component: lazy(() => import(`./report`)),
    },
    {
      menu: "sidenav.application",
      path: `${APP_PREFIX_PATH}/application`,
      component: lazy(() => import(`./application`)),
    },
    {
      menu: "sidenav.analytic",
      path: `${APP_PREFIX_PATH}/analytic`,
      component: lazy(() => import(`./analytic`)),
    },
  ];

  const menus = _.chain(listMenu)
    .filter((item) => {
      const findMenu = _.find(accessMenus, (item2) => item2.menu === item.menu);
      if (findMenu) {
        return item;
      }
    })
    .map((item) => {
      const findMenu = _.find(accessMenus, (item2) => item2.menu === item.menu);
      return { ...item, subMenu: findMenu.subMenu };
    })
    .value();

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./dashboard`))}
        />
        {menus.length &&
          menus.map(({ path, component: ComponentWrapper, subMenu }) => (
            <Route path={path}>
              <ComponentWrapper subMenu={subMenu} />
            </Route>
          ))}
        <Route
          path={`${APP_PREFIX_PATH}/404`}
          component={lazy(() => import(`../auth-views/page-not-found`))}
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
