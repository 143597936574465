import FetchInterceptor from "configs/FetchInterceptor";

class RolesServices { 
  static async getAllRole(payload) {
    const response = await FetchInterceptor.post("/core/v1/roles/get/all", payload);
    return response;
  }

  static async addRole(payload) {
    const response = await FetchInterceptor.post("/core/v1/roles/add", payload);
    return response;
  }

  static async updateRole(payload) {
    const response = await FetchInterceptor.post("/core/v1/roles/update", payload);
    return response;
  }
}

export default RolesServices;
