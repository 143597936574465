import FetchInterceptor from "configs/FetchInterceptor";

class JobCostingServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v2/product-manufacturing/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async simulateCalculation(requestBody) {
    const apiUrl = "/core/v2/product-manufacturing/simulate-calculation";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v2/product-manufacturing/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v2/product-manufacturing/get/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async process(id) {
    const apiUrl = `/core/v2/product-manufacturing/process/${id}`;
    const response = await FetchInterceptor.post(apiUrl);
    return response;
  }

  static async cancel(id) {
    const apiUrl = `/core/v2/product-manufacturing/cancel/${id}`;
    const response = await FetchInterceptor.post(apiUrl);
    return response;
  }

  static async getPdf(id) {
    const apiUrl = `/core/v2/product-manufacturing/print/${id}`;
    const response = await FetchInterceptor.get(apiUrl, {
      responseType: "blob",
    });
    return response;
  }
}
export default JobCostingServices;
