import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { authContants, authenticated, showAuthMessage, signOutSuccess } from ".";
import { setStateUser, setDefaultUser } from "redux/actions/User";
import { SET_DEFAULT_USER } from "redux/constants/User";

import { BranchServices, EmployeesServices } from "services";

export function* signIn() {
  yield takeEvery(authContants.SIGNIN, function* ({ payload }) {
    try {
      const user = yield call(EmployeesServices.signIn, payload);
      localStorage.setItem(authContants.ACTIVE_USER, user.employee.email);
      yield call(BranchServices.resetBranch, { employeeId: user.employee.id });
      yield put(setStateUser({ currentUser: user }));
      yield put(authenticated(user.employee.email));
    } catch (err) {
      if (err.response)
        yield put(showAuthMessage("Mohon periksa kembali email dan kata sandi Anda!"));
    }
  });
}

export function* signOut() {
  yield takeEvery(authContants.SIGNOUT, function* () {
    try {
      const userActive = localStorage.getItem(authContants.ACTIVE_USER);
      const requestBody = { email: userActive };
      yield call(EmployeesServices.signOut, requestBody);
      localStorage.removeItem(authContants.ACTIVE_USER);
      yield put(setDefaultUser());
    } catch (err) {
      if (err.response) yield put(showAuthMessage("Mohon coba kembali!"));
    }
  });
}

export function* removeUserSession() {
  yield takeEvery(SET_DEFAULT_USER, function* () {
    try {
      yield put(signOutSuccess());
    } catch (err) {
      if (err.response) yield put(showAuthMessage("Mohon coba kembali!"));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(signIn), fork(signOut), fork(removeUserSession)]);
}
