import FetchInterceptor from "configs/FetchInterceptor";

class AccessMenuServices {
  static async getAll() {
    const response = await FetchInterceptor.get("/core/v1/access-menus/get/all");
    return response;
  }
}

export default AccessMenuServices;
